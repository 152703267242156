(function (window, document, undefined) {

    function decrypt(value) {
        value = decodeURIComponent(value);
        let output = '';
        for (let i = 0, l = value.length; i < l; i++) {
            output += String.fromCharCode(value.charCodeAt(i) ^ 69);
        }
        return output;
    }

    const ready = () => {
        const protectedElements = document.querySelectorAll('[data-protected]');
        protectedElements.forEach(protectedElement => {
            protectedElement.outerHTML = decrypt(protectedElement.dataset.protected);
        });
    };
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', ready);
    }
    else {
        ready();
    }

})(window, window.document);